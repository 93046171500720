import React, {useState, useEffect, useRef} from 'react';
import '../Styles/Home.css';
import '../Styles/App.css';
import cloud from '../Images/cloud.png';
import * as globals from './Globals';
import Tooltip from './ToolTip';

import axios from 'axios';

interface SuccessFailureProps {
  type: string;
  message: string;
}

const SuccessFailure: React.FC<SuccessFailureProps> = ({ type, message}) => {
  let iconBox = '';
  if (type === 'success') {
    iconBox = 'success-box';
  }else{
    iconBox = 'failure-box';
  }
  
  return (
    <div className='message-outer-container'>
      <div className = {iconBox}>
        {type === 'success' && <p className='icon'>{'\u2713'}</p>}
        {type === 'failure' && <p className='icon'>{'\u2717'}</p>}
      </div>
      <p className='message'>{message}</p>
    </div>
   
  );
};

function Home() {

  const [modulesList, setModulesList] = useState([]);

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [insufficientCredits, setInsufficientCredits] = useState(false);
  const [atLeastOne, setAtLeastOne] = useState(false);
  const [textValue, setTextValue] = useState('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);

  // Source: https://stackoverflow.com/questions/4852017/how-to-initialize-an-arrays-length-in-javascript
  //    and: https://stackoverflow.com/questions/13782698/get-total-number-of-items-on-json-object
  // Generate a list of checkbox value holders which is the same as the length of the module list
  //   retrieved from the server.
  const [checkboxes, setCheckboxes] = useState<Array<boolean>>([]);

  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index] = !updatedCheckboxes[index];
    setCheckboxes(updatedCheckboxes);
    console.log(updatedCheckboxes);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let selectedFile = null;
    if (event.target.files){
      selectedFile = event.target.files[0]
    } else{
      selectedFile = null;
    }
    setFile(selectedFile);
  };

  const getCheckedCount = () => {
    return checkboxes.filter((isChecked) => isChecked).length;
  };

  const handleClick = () => {
    const isAnyCheckboxSelected = getCheckedCount() === 0;
    const credits = globals.getCredits();
    
    if (isAnyCheckboxSelected) {
      setAtLeastOne(true);
    } else if (credits < getCheckedCount()) {
      setInsufficientCredits(true);
    }else{
      // do API call here

      //console.log(textValue);

      axios.put(globals.getApiPath() + 'api/v1/file/text/', {'text': textValue})
        .then((data) => {
          //console.log(data);

          // Now generate the jobs
          // TODO: This must be made MUCH less hacky and ugly!!!

          // the data returned from the upload call is a simple int representing the new file ID.
          // we can prepopulate that ID here.
          var jobData = {'module_id': 1, 'source_id': data}
          // the mapping is:
          // name      js db
          // LEX       0  3
          // SYNT      1  6
          // GRAMMAR   2  2
          // MLL2      3  4
          // CAPTURA   4  1
          // SENTIMENT 5  5

          //console.dir(checkboxes);
          for (var n = 0; n < checkboxes.length; n++) {
            //console.log(n);
            //console.log(checkboxes[n]);
            if (checkboxes[n]) {
              var mId = n;
              
              // THIS IS A HORRIBLE IDEA.
              // It's only here to get it working fast
              // IF YOU PUBLISH THE CODE WITH THIS THE CODE GODS WILL HUNT YOU DOWN 
              //    AND RANSOMWARE YOU TO HELL.

              // if (n == 0) mId = 3;
              // if (n == 1) mId = 6
              // if (n == 3) mId = 4
              // if (n == 4) mId = 1;

              jobData['module_id'] = mId;

              // make the API request to queue the job
              globals.api_call.put(globals.getApiPath() + "api/v1/job", jobData)
                .then((response2) => {
                  console.debug(response2.data);
                });
            }
          }

          setCheckboxes([false, false, false, false, false, false]);
          setTextValue('');
          setFile(null);
          setShowSuccessMessage(true);      

        })
        .catch((err) => {
          console.log(err.message);
          
        });
    }
  };

  useEffect(() => {
    globals.api_call.get(globals.getApiPath() + "/api/v1/module/")
    .then(response => {
      if (Object.keys(modulesList).length == 0) 
        setModulesList(response.data);
        setCheckboxes(Array.from({length: Object.keys(response.data).length}, (v, i) => false));
    })
    .catch(err => {
      console.log(err);
      window.location.href = '/down';
    })  
  }, [modulesList]);

  useEffect(() => {
    if (showSuccessMessage || insufficientCredits || atLeastOne) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
        setInsufficientCredits(false); 
        setAtLeastOne(false);
      }, 3000); // Adjust the duration (in milliseconds) as needed
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage, insufficientCredits, atLeastOne]);

  return (
    <main className="background-one">
      <div className = 'main-page-containers'>
        <div className='Upload'>
          <h1>Welcome to SE&Ntilde;AL!</h1>
          <p>SE&Ntilde;AL is a modular NLP (natural language processing) application intended to analyze and evaluate different components of essays written by students of Spanish as a second language (L2). </p>
          <p>Currently, SE&Ntilde;AL can provide a lexical-syntactic analysis of the complexity of an L2 essay, information about the frequency of various grammatical forms (such as nouns, adjectives, adverbs, and various verb tenses), a grammatical agreement checker, a ML L2 proficiency classifier, and a machine translation identification system.</p>
          <p>To get started, <a href="/signup">sign up</a> for a free account and then try typing or pasting some text below!</p>
        </div>
          <div className='Upload'>
            <p className='main-text'>Start Analyzing!</p>
            {!globals.isLoggedIn() && <p>Please <a href="/login">log in</a> or <a href="signup">sign up</a> to use the analyzer.</p>}
            <textarea placeholder='Enter Student Text' 
            className='input-upload'
            value={textValue}
            onChange={(e) => setTextValue(e.target.value)}
            ></textarea>
            {/*
            <p className='main-text'>Or</p>
            <div className='file-upload-container'>
              <div className='cloud-and-file-upload'>
              <img src = {cloud} className='cloud-image'></img>
              <input type='file' 
                id='file-upload' 
                className='choose-file-upload'
                ref={fileInputRef}
                onChange={handleFileChange}
              ></input>
              </div>
            </div>
            */}
            <p className='main-text'>Select Analysis Types</p>
            <p className='credit-reminder'>(1 Credit Each)</p>
            <div className='checkbox-container'>
              { modulesList.map((v) => {
                //let thisKey: number = Number(k);
                //let thisValue: Object = modulesList[thisKey];
                return ( 
                  <div className='checkbox-and-label'>
                  <input className='checkbox' type='checkbox' checked={checkboxes[v['id']]} onChange={() => handleCheckboxChange(v['id'])}></input>
                  <Tooltip text={v['name']} tooltip={v['description']} />
                  </div>
                )
                })
              }
            </div>
            {showSuccessMessage &&  <SuccessFailure message={'Success! Results will appear in library when complete.'} type = {'success'}></SuccessFailure>}
            {insufficientCredits && <SuccessFailure message={'Insufficient Credits'} type = {'failure'}></SuccessFailure>}
            {atLeastOne && <SuccessFailure message={'Please Select a Checkbox'} type = {'failure'}></SuccessFailure>}
            <button className='submit-text-button' onClick={handleClick} disabled={!globals.isLoggedIn() || (file === null && textValue === '')}>
              {!globals.isLoggedIn() && <label>Log In to Analyze</label>}
              {globals.isLoggedIn() && <label>Start Analysis!</label>}
            </button>
          </div>
        </div>
    </main>
  );
}

export default Home;
