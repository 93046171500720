import React , {useState} from 'react';
import '../Styles/Login.css';
import logo from '../Images/logo.png';
import { BrowserRouter as Router, Switch, Route, Link, useLocation} from 'react-router-dom';


function PwdReset(){
    const [emailSent, setEmailSent] = useState<Boolean>(false);
    const [codeEntered, setCodeEntered] = useState<Boolean>(false);

    const handleSendCodeButtonClick = () =>{
        setEmailSent(true);
    }

    const handleResetPasswordButtonClick = () =>{
        setCodeEntered(true);
    }

    return (
        <main className='login-background'>
          <img className = 'logo' src={logo}></img>
          <Link to = '/'>
            <button className = 'back-to-home-btn'>
              <p className='bth-text'>Back To Home</p>
            </button>
          </Link>  
          <div className='pwd-reset-container'>
            <p className='password-reset-title'>Password Reset</p>
            {!emailSent &&
            <div>
            <input placeholder='Email' className='login-inputs'></input>
            <button className = 'login-button' onClick={handleSendCodeButtonClick}>
              <p className='login-btn-text'>Send Code</p>
            </button>
            </div>
            }
            {emailSent && !codeEntered &&
            <div>
            <input placeholder='Enter Code' className='login-inputs'></input>
            <div className='resend-code-container'>
                <p className='no-account-text'>Can't find it?</p>
                <Link className = 'sign-up-link' to = '/password-reset'> {/*resend email*/}
                    <p className='sign-up-link'>Resend</p>
                </Link>
                </div>
            <button className = 'login-button' onClick={handleResetPasswordButtonClick}>
              <p className='login-btn-text'>Reset Password</p>
            </button>
            </div>}
            {codeEntered && 
            <div>
            <input type = 'password' placeholder='Password' className='login-inputs'></input>
            <input type = 'password' placeholder='Confirm Password' className='login-inputs'></input>
            <Link to = '/login'>
            <button className = 'login-button'>
              <p className='login-btn-text'>Reset Password</p>
            </button>
            </Link>
            </div>}
          </div>
        </main>
      );

}

export default PwdReset