import React from 'react';
import '../Styles/Home.css';
import Footer from './Footer';
import '../Styles/Plans.css';
import '../Styles/Account.css';
import * as globals from './Globals'

function Account() {
  return (
    <div className="background-one">
      <main className='main'>
      <p className='header-text'>
        Account
      </p>
      <div className='info-container'>
        <div className='field-container'>
        <span>First Name: </span>
        <div className='user-info'>
          <span>{globals.getFirstName()}</span>
        </div>
        </div>
        <div className='field-container'>
        <span>Last Name: </span>
        <div className='user-info'>
          <span>{globals.getLastName()}</span>
        </div>
        </div>
        <div className='field-container'>
        <span>Email: </span>
        <div className='email'>
          <span>{globals.getEmail()}</span>
        </div>
        </div>
        <div className='field-container'>
        <span>Account Type: </span>
        <div className='account-type'>
          <span>{globals.getAccountType()}</span>
        </div>
        </div>
        <div className='field-container'>
        <span>Credits: </span>
        <div className='credits'>
          <span>{globals.getCredits()}</span>
        </div>
        </div>
      </div>
      </main>
    </div>
  );
}

export default Account;