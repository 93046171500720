import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';
import '../Styles/UserMenu.css';
import * as globals from './Globals';

const UserMenu: React.FC = () => {
  
    const handleLogoutClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
        //globals.setLoggedIn(false);
        globals.logout();
      };
    
    return (
    <nav className="user-menu">
      <ul className='user-menu-ul'>
      <Link className = 'link' to = '/account'>
        <li className='user-menu-li'>
                Account
        </li>
        </Link>
        <Link className = 'link' to = '/library'>
        <li className='user-menu-li'>
                Library
        </li>
        </Link>
        <Link className = 'link' to = '/' onClick = {handleLogoutClick}>
        <li className='user-menu-li-logout'>
                Logout
        </li>
        </Link>
      </ul>
    </nav>
  );
};

export default UserMenu;