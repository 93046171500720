import axios, { AxiosError } from "axios";

/*Remove these when api calls are implemented */

let userMenuOpen = false;
let credits = 0;

let apiPath: string = (process.env.SENAL_API_URL as string);

if (apiPath == undefined) {
  apiPath = "";
}

export const api_call = axios.create({
    baseURL: apiPath
});

api_call.interceptors.request.use(
  function(config) {
    const token = localStorage.getItem("token"); 
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// This global function should be used as the error handler for any API request
// that requires authorization. It will kick the user to the login page any time
// an API request happens that requires authorization if that authorization is
// invalid.
export function unauthorizedHandler(err: AxiosError): void {
    if (err.response!.status == 401 || err.response!.status == 403) {
      // redirect user to login page
      console.error("Missing or invalid token. Directing to login page.");
      window.location.href = '/login';
      return;
    }
    console.dir(err);
}

export function logout(): void {
  sessionStorage.removeItem("userInfo");
  localStorage.removeItem("token");
}

export function isLoggedIn(): boolean {
  return (sessionStorage.getItem('userInfo') !== null);
}

export function setAuthToken(token: string): void {
    if (token != "") {
        localStorage.setItem("token", token);
    }
    else {
        localStorage.removeItem("token");
    }
 }

export function getFirstName(): string { 
  if (sessionStorage.getItem('userInfo') !== null) {
    var userInfo = sessionStorage.getItem('userInfo');
    return JSON.parse(userInfo!)['first_name'];
  }
  else return "";
}

export function getLastName(): string { 
  if (sessionStorage.getItem('userInfo') !== null) {
    var userInfo = sessionStorage.getItem('userInfo');
    return JSON.parse(userInfo!)['last_name'];
  }
  else return "";
}

export function getCredits(): number { 
  if (sessionStorage.getItem('userInfo') !== null) {
    var userInfo = sessionStorage.getItem('userInfo');
    return Number(JSON.parse(userInfo!)['credit_balance']);
  }
  else return 0;
}

export function getAccountType(): number {
  if (sessionStorage.getItem('userInfo') !== null) {
    var userInfo = sessionStorage.getItem('userInfo');
    return Number(JSON.parse(userInfo!)['userclass']);
  }
  else return -1;
}

export function getEmail(): string {
  if (sessionStorage.getItem('userInfo') !== null) {
    var userInfo = sessionStorage.getItem('userInfo');
    return JSON.parse(userInfo!)['email'];
  }
  else return "";
}

export function getUserMenu(): boolean { return userMenuOpen; }
export function setUserMenu(value: boolean): void { userMenuOpen = value; }

export function getApiPath(): string { return apiPath; }