import logo from '../Images/logo.png'
import React, {useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useLocation} from 'react-router-dom';
import '../Styles/Navbar.css';
import * as globals from './Globals';
import UserMenu from './UserMenu';

const Navbar: React.FC = () => {
    const location = useLocation();
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);

    // If we do not have a copy of the User data in the session storage but we have a token, get it.
    if (sessionStorage.getItem("userInfo") == null && localStorage.getItem("token") !== null) {
      globals.api_call(globals.getApiPath() + "api/v1/user/")
        .then(response => {
          sessionStorage.setItem("userInfo",JSON.stringify(response.data));
        })
        .catch(err => {
          // any error, delete the token
          console.log(err);
          globals.logout();
        });
    }

    const hideUserMenu = () => {
        setUserMenuOpen(false);
    }

    const showUserMenu = () => {
      setUserMenuOpen(true);
    }

    const excludeNavBar = location.pathname === '/login' || location.pathname === '/signup' || location.pathname === '/password-reset';

    if (excludeNavBar){
        return null;
    }

    return(
      <main onMouseLeave={hideUserMenu}>
        <nav className='navbar'>
        <Link to='/'>
        <img src = {logo}/>
        </Link>  
          <ul>
              <li>
                <Link 
                className = {location.pathname === '/' ? 'nav-link-active' :'nav-link'}
                to ='/'>Home</Link>
              </li>
              <li>
                <Link 
                className = {location.pathname === '/about' ? 'nav-link-active' :'nav-link'}
                to ='/about'>About</Link>
              </li>
              <li>
                <Link 
                className = {location.pathname === '/plans' ? 'nav-link-active' :'nav-link'}
                to ='/plans'>Plans</Link>
              </li>
          </ul>
          {!globals.isLoggedIn() &&
          <Link to = '/login'>
          <button className = 'sign-in' onClick={hideUserMenu}>
            <p className='log-in'>Login</p>
          </button>
          </Link>
          }
          {globals.isLoggedIn() &&
            <button className = 'logged-in-icon' onMouseEnter = {showUserMenu}>
              <p className='log-in'>Hi, {globals.getFirstName()}</p>
            </button>
          }
          
        </nav>
        {userMenuOpen && globals.isLoggedIn() && <UserMenu/>}
        </main>
    )

}

export default Navbar;