import React, { useState, useEffect } from 'react';
import '../Styles/Home.css';
import Footer from './Footer';
import '../Styles/Plans.css';
import '../Styles/Library.css';
import TableAnalyses from './TableAnalyses';
import TableUploads from './TableUploads';
import axios from 'axios';
import * as globals from './Globals';

function Library() {
  const aData = [
    { files: 'Submission_2023-06-01_15-00-00.txt', modules: 'LEX', date: 'Wed, 07 Jun 2023 23:49:17 GMT', results: 'results'},
  ];

  const [uploads, setUploads] = useState([]);
  const [analyses, setAnalyses] = useState([]);

  useEffect(() => {
    globals.api_call.get(globals.getApiPath() + 'api/v1/file/')
        .then((data) => {
          setUploads(data.data);
        })
        .catch(globals.unauthorizedHandler);

    globals.api_call.get(globals.getApiPath() + 'api/v1/job/')
      .then((data) => {
        setAnalyses(data.data);
      })
  }, []);

  return (
    <div className="background-five">
      <main className='main'>
      <h1 className='header-text'>
        Library
      </h1>

      <h2 className='header-text-h2'>
        Analysis Results
      </h2>

      <div className='placeholder'>
        <div>
          <TableAnalyses data={analyses} />
        </div>
      </div>

      <h2 className='header-text-h2'>
        Uploaded Files
      </h2>

      <div className='placeholder'>
        <div>
          <TableUploads data={uploads} />
        </div>
      </div>

      </main>

      <div className="footer-text">
        You have used <strong>10%</strong> (5.0 MB) of your 50.0 MB of storage.
      </div>
    </div>
  );
}

export default Library;