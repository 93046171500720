import '../Styles/Login.css';
import logo from '../Images/logo.png';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom';


function SignUp() {
  return (
    <main className='login-background'>
      <img className = 'logo' src={logo}></img>
      <Link to = '/'>
        <button className = 'back-to-home-btn'>
          <p className='bth-text'>Back To Home</p>
        </button>
      </Link>  
      <div className='signup-outer-container'>
        <p className='login-text'>Sign Up</p>
        <input placeholder='First Name' className='login-inputs'></input>
        <input placeholder='Last Name' className='login-inputs'></input>
        <input placeholder='Email' className='login-inputs'></input>
        <input type = 'password' placeholder='Password' className='login-inputs'></input>
        <input type = 'password' placeholder='Confirm Password' className='login-inputs'></input>
        <Link to = '/'>
        <button className = 'signup-button'>
          <p className='login-btn-text'>Sign Up</p>
        </button>
      </Link>  
      </div>
      
    </main>
  );
}
export default SignUp;
