import '../Styles/App.css'
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useLocation} from 'react-router-dom';
import Home from './Home';
import About from './About';
import Plans from './Plans';
import Login from './Login';
import Navbar from './Navbar';
import Signup from './Signup';
import PwdReset from './PwdReset';
import Account from './Account';
import Library from './Library';
import Footer from './Footer';

import SiteDown from './SiteDown';

const App: React.FC = () => {
  return (
    <main>
      <Router>
        <Navbar/>
      <Switch>
        <Route exact path="/" component={Home}/>
        <Route path = "/about" component={About}/>
        <Route path = "/plans" component={Plans}/>
        <Route path = "/login" component={Login}/>
        <Route path = "/signup" component={Signup}/>
        <Route path = "/password-reset" component={PwdReset}/>
        <Route path = "/account" component={Account}/>
        <Route path = "/library" component={Library}/>
        <Route path = "/down" component={SiteDown}/>
      </Switch>
      <Footer/>
      </Router>
      </main>
    
  );
};

export default App;