import React, { useState } from "react";
import "../Styles/CollapsibleContainer.css";

interface CollapsibleContainerProps {
  header: React.ReactNode;
  children: React.ReactNode;
}

const CollapsibleContainer: React.FC<CollapsibleContainerProps> = ({ header, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div>
      <div className={`header ${isExpanded ? "expanded" : ""}`} onClick={toggleExpand}>
        <span className="triangle">&#9654;</span>
        {header}
      </div>
      {isExpanded && <div>{children}</div>}
    </div>
  );
};

export default CollapsibleContainer;