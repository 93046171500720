import React from 'react';
import '../Styles/About.css';
import CollapsibleContainer from './CollapseContainer';

function Scoring() {


  return (
    <div className='background-three'>
      <main className='main'>
      <div className = 'container'>
      <CollapsibleContainer header={<p className='header'>About Us</p>}>
        <div className='about-us-container'>
        <div>
        <p>
        SENAL is a modular NLP application intended to analyze and evaluate different 
        components of essays written by students of Spanish 
        as a second language (L2). Currently, SEÑAL can provide a lexical-syntactic analysis 
        of the complexity of an L2 essay, information about the frequency of various grammatical 
        forms (such as nouns, adjectives, adverbs, and various verb tenses), a grammatical
        agreement checker, a ML L2 proficiency classifier, and a machine translation identification
        system.
        </p>
        </div>
        </div>
      </CollapsibleContainer>
      <CollapsibleContainer header={<p className='header'>Analysis Modules</p>}>
        <div className='all-modules-container'>
        <ol className='modules-list'>
          <div className='module-container'>
          <li>SEÑAL-LEX</li>
          <p>
          This module analyzes lexical sophistication. First,
          this result displays the degree of advanced lexicon and lower frequency items in the repertoire of the
          learner (Bulte and Housen, 2014). In addition, this analysis provides a window to the mental vocabulary 
          available in the learner and the expansion of the Spanish resources during a semester of classes (Jarvis, 2013).
          </p>
          </div>
          <div className='module-container'>
          <li>SEÑAL-SYNT</li>
          <p>
          This module of SENAL focuses on measuring the proportional production
          of predicate constructions (i.e., number of verbs and auxiliary verbs), sentence length 
          (i.e., number of words and sentential coordination, measured in T-Units (Bardovi-Harlig, 1992) and conjunctions)
          and sentence complexity (i.e., number of clauses, both relative and adverbial).
          </p>
          </div>
          <div className='module-container'>
          <li>SEÑAL-GRAMMAR</li>
          <p>
          This module utilizes a frequency profile of different grammatical elements. 
          These included the total number of nouns,
          adjectives, adverbs, and verbs incorporated in the
          composition, as well as a breakdown of different
          tenses and aspects used by the learner, namely:
          present indicative, present subjunctive, preterite,
          imperfect indicative, imperfect subjunctive, simple
          future, and simple conditional. Similarly, this module also 
          contains a gender and number agreement checker between the different nouns found in the
          text and their corresponding articles and adjectives.
          This component works by identifying the gender
          and number of each noun, and matching these with
          the gender and number assigned to the articles and
          adjectives within the noun clause by the parser. The
          output of this module shows the results in terms of
          relative frequencies.
          </p>
          </div>
          <div className='module-container'>
          <li>SEÑAL-MLL2</li>
          <p>
          SENAL-MLL2 is a machine learning classifier of L2 writing proficiency. The classifier was trained
          using the Spanish version of the transfomer-based
          language model of BERT (Canete et al., 2020). The
          training data involves a freely-available corpus of
          Spanish L2 compositions, CEDEL2 (Lozano et al.,
          2009). The corpus is composed of 3034 essays written by Spanish L2 learners with English as their L1.
          Six training arguments were employed, including
          Lower Beginner, Upper Beginner, Lower Intermediate, Upper intermediate, Lower Advanced, and Upper Advanced. These proficiency labels were rated
          by human instructors according to their placement
          test scores and are based on the Common European
          Framework of Reference for Languages.
          </p>
          </div>
          <div className='module-container'>
          <li>SEÑAL-CAPTURA</li>
          <p>
          CAPTURA allows the instructor to check whether
          a suspicious L2 text produced by an English L1
          learner of Spanish has been copied verbatim from
          Google’s machine translation service. We used the
          free allowance of Google Translate API (500,000
          characters) to deploy the first version of the module.
          </p>
          </div>
          <div className='module-container'>
          <li>SEÑAL-SENTIMENT</li>
          <p>
          This module is a sentiment analyzer of Spanish L2 compositions. 
          </p>
          </div>
        </ol>
      </div>
      </CollapsibleContainer>
      <CollapsibleContainer header={<p className='header'>Scoring</p>}>
      <div className='explanation-section'>
      <div className='explanation-container'>
        <p className='explanation-title'>
          SEÑAL scores students into one of our 6 proficiency levels
        </p>
        <div className='levels-container'>
          <ol className='levels-list'>
            <li>Lower Beginner</li>
            <li>Upper Beginner</li>
            <li>Lower Intermediate</li>
            <li>Upper Intermediate</li>
            <li>Lower Advanced</li>
            <li>Upper Advanced</li>
          </ol>
        </div>
      </div>
    </div>
    <p className='sample-header'>
      Sample Text For Each Level
    </p>
    <div className='level-example-container'>
      <ol className='level-example-list'>
          <li>Lower Beginner</li>
          <p>
            Quiero estar un director de película bueno. Son mi tradajo soñar. 
            Me gusta mucho películas de horror y comedia. Tengo mas películas de DVD. 
            Yo estudio película en la universidad de Georgia State.
          </p>
          <li>Upper Beginner</li>
          <p>
          En el futuro, yo quiero dar un diploma de 
          comunicacións a Penn State University. Entonces, 
          yo quiero a obtinar un trabajo de película y 
          fotografía. Yo prefiero un trbajo en Pennsylvania.
          Quizá, me abro mi propio negocio en DuBois.
          </p>
          <li>Lower Intermediate</li>
          <p>
          El robó coches, una moto y una helicoptre y cruzó la 
          ciudad dejando un rastro de sangre, rotos coches y edificios. 
          La estilo es muy rapido, con muchos escenas no mi gusta la sexualidad
          sin razon - hay muchas pechas desnudas pero no sé porque.
          </p>
          <li>Upper Intermediate</li>
          <p>
          Mi persona famosa es un buen actor. Su nombre es Leonardo 
          DiCaprio. El es mi actor favorito porque el es muy guapo, y es el buen actor 
          de todo el mundo. El había presentado muchas
          ganas de acting, pero él nunca gana. Es el mayor triste de todo el mundo.
          </p>
          <li>Lower Advanced</li>
          <p>
          Mientras, su esposa embarazada, una anglosajona, 
          está intentando averiguar donde está su marido. 
          Tiene un amigo que trabaja para un político que la ayuda y, 
          poco a poca, dan cuenta que el CIA está involucrado en la 
          desaparacencia de su marido.
          </p>
          <li>Upper Advanced</li>
          <p>
          Hace dos días, un amiga y yo fuimos a ver una película méxicana 
          se llama Bajo la misma luna. (No puedo subrayar el texto). Uno 
          de mis maestros recomendaron que veamos esta película porque es 
          muy linda. Normalmente, no voy a ver muchas películas porque son 
          muy caros. Prefiero esperar hasta que sean disponibles en DVD. 
          </p>
        </ol>
      </div>
      </CollapsibleContainer>
    </div>
    </main>
    </div>
  );
}

export default Scoring;
