import '../Styles/Login.css';
import logo from '../Images/logo.png';
import React, {useState} from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useLocation, useHistory} from 'react-router-dom';
import * as globals from './Globals';
import axios from 'axios';

function Login() {

  // This will hold the parameters for the login
  const [params, setParams] = useState({
    "email": "",
    "password": ""
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setParams(prevParams => ({
        ...prevParams,
        [name]: value
    }));
  };

  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const validateEmail = (email: string): boolean => {
    // Simple email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePassword = (password: string): boolean => {
    // Password validation rules
    // Example: Password must be at least 8 characters long
    return password.length >= 8;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    console.dir(params);

    if (!validateEmail(params["email"])) {
      setErrorMessage('Invalid email');
      return;
    }

    if (!validatePassword(params["password"])) {
      setErrorMessage('Invalid password');
      return;
    }

    // Perform authentication logic here
    // e.g., call an API endpoint to authenticate the user
    // or perform client-side authentication logic

    axios.post(globals.getApiPath() + "api/v1/login/", params)
      .then(response => {

        setErrorMessage('');

        // get token from response
        //console.log("Payload from server: ");          
        //console.dir(response.data);

        const token = response.data;

        //set token to axios common header
        globals.setAuthToken(token);
  
        // Reset form fields and error message
        //globals.setLoggedIn(true);

        // Get user info from the API
        // Since we just set the token this request should send the new token.
        globals.api_call.get(globals.getApiPath() + "api/v1/user/")
          .then(response => {
            console.log(response.data);

            // globals.setFirstName(response.data['first_name']);
            // globals.setLastName(response.data['last_name']);
            // globals.setCredits(response.data['credit_balance']);

            //redirect user to home page
            history.push('/');
            window.location.href = '/'
    
          })
          .catch(err => {

            if (err.response!.status == 401) {
              setErrorMessage("Account not found. Please contact the app developer for assistance.");
              globals.setAuthToken('');
              //globals.setLoggedIn(false);

            }
            
          });

    })
    .catch(err => console.log(err));

  };
  return (
    <main className='login-background'>
      <img className = 'logo' src={logo}></img>
      <Link to = '/'>
        <button className = 'back-to-home-btn'>
          <p className='bth-text'>Back To Home</p>
        </button>
      </Link>  
      <form className='login-container' onSubmit={handleSubmit}>
        <p className='login-text'>Login</p>
        {errorMessage && <p className='error-message'>{errorMessage}</p>}
        <input 
            type = 'email' 
            placeholder='Email' 
            className='login-inputs'
            name="email"
            onChange={handleChange}
        ></input>
        <input 
            type='password' 
            placeholder='Password' 
            className='login-inputs'
            name="password"
            onChange={handleChange}
        ></input>
        {/*forgot password link - enable when gmail is set up for site*/}
        {/* <Link className='forgot-password-link' to = '/password-reset'>
          <p>Forgot Password?</p>
        </Link> */}
        <button className = 'login-button' type = 'submit'>
          <p className='login-btn-text'>Login</p>
        </button>
      <div className='sign-up-container'>
        <p className='no-account-text'>Don't have an account?</p>
        <Link className = 'sign-up-link' to = '/signup'>
          <p className='sign-up-link'>Sign Up</p>
        </Link>
      </div>
      </form>
      
    </main>
  );
}

export default Login;
