import React from "react";
import '../Styles/Footer.css'
import { BrowserRouter as Router, Switch, Route, Link, useLocation} from 'react-router-dom';

const EmailLink = () => {
    const email = "falcon.restreporamos@mnsu.edu";
    const subject = "SEÑAL Support";
  
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}`;
  
    return (
      <a href={mailtoLink}>Contact Us</a>
    );
  };

const Footer: React.FC = () => {

    if (useLocation().pathname == '/login' || useLocation().pathname == '/signup'){
        return null;
    }

    return(
        <footer>
            <nav>
            <ul>
              <li>
                <Link 
                className='footer-links'
                to ='/'>Home</Link>
              </li>
              <span>-</span>
              <li>
                <Link 
                className = 'footer-links'
                to ='/about'>About</Link>
              </li>
              <span>-</span>
              <li>
                <Link 
                className = 'footer-links'
                to ='/plans'>Plans</Link>
              </li>
          </ul>
          
        </nav>
        <div>
        <span>Need Support?</span>
        <EmailLink />
        </div>
    </footer>
    )

}

export default Footer;