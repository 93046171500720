import React from "react";
import { BrowserRouter as Router, Switch, Route, Link, useLocation} from 'react-router-dom';

const SiteDown: React.FC = () => {

    return (
      <div>
        <h1>Website is Down</h1>

        <p>The SE&Ntilde;AL website is currently experiencing a problem or is under routine maintenance. Please try again shortly. If you continue to receive this error for a prolonged time, please contact 
          the <a href="mailto:flint.million@mnsu.edu">site administrator</a> at flint.million@mnsu.edu or the <a href="mailto:falcon.restreporamos@mnsu.edu">site owner</a> at falcon.restreporamos@mnsu.edu.
        </p>
      </div>
    )

}

export default SiteDown;