import React from 'react';
import '../Styles/ToolTip.css';

interface TooltipProps {
  text: string;
  tooltip: string;
}

const Tooltip: React.FC<TooltipProps> = ({ text, tooltip }) => {
  return (
    <span className="tooltip">
      {text}
      <span className="tooltip-text">{tooltip}</span>
    </span>
  );
};

export default Tooltip;